import React from "react"
//import * as THREE from "three"

class Marquee extends React.Component {
  constructor(props) {
    super(props);
    this.canvasContainer = React.createRef();
  }
  componentDidMount() {

    // get props
    const props = this.props

    // Create Canvas
    let canvas = this.canvasContainer.current
    canvas.width = 1650;
    canvas.height = 765;
    canvas.style.width  = '100%';
    canvas.style.height = 'auto';
    canvas.yloc = 0;
    canvas.xLoc = canvas.width;

    let ctx = canvas.getContext('2d')

    // Image Loader
    let banner = new Image()
    banner.src = props.banner.src
    let baseYloc = 0
    let yloc = 0
    let FONT_NAME = 'Millionaire Script';
    ctx.font = `400px "${FONT_NAME}"`;
    let textWidth = ctx.measureText(props.articletitle.toUpperCase()).width

    banner.onload = function () {
      ctx.drawImage(banner, 225, 0);
    }

    function draw() {
      //ctx.globalCompositeOperation = 'overlay';
      ctx.font = `400px "${FONT_NAME}"`;
      ctx.fillStyle = "black"
      ctx.textAlign = "left"
      ctx.textBaseline = 'top'
      ctx.fillText(props.articletitle.toUpperCase(), yloc, 300);
      ctx.save();
      ctx.drawImage(banner, 225, 0);
    }

    function update() {
      if(yloc === Math.trunc(baseYloc - textWidth - 2150)) {
        yloc = 2150;
      }
      else {
        yloc--;
      }
      draw()
    }

    let animationID;
    function animate() {
      ctx.clearRect(0,0,canvas.width,canvas.height)
      animationID = requestAnimationFrame(animate)
      update()
    }

    function isInViewport (element) {
      var rect = element.getBoundingClientRect(), top = rect.top, height = rect.height,
      el = element.parentNode
      // Check if bottom of the element is off the page
      if (rect.bottom < 0) return false
      // Check its within the document viewport
      if (top > document.documentElement.clientHeight) return false
      do {
        rect = el.getBoundingClientRect()
        if ((top <= rect.bottom) === false) return false
        // Check if the element is out of view due to a container scrolling
        if ((top + height) <= rect.top) return false
        el = el.parentNode
      } while (el !== document.body)
      return true
    }

    function startAnimation () {
      if (isInViewport(canvas)) {
        if (canvas.getAttribute("fx-visible") !== "true") {
          animate()
          console.log("start animate "+props.articletitle)
        }
        canvas.setAttribute("fx-visible", true)
      } else {
        if (canvas.getAttribute("fx-visible") !== "false") {
          cancelAnimationFrame(animationID)
          console.log("stop animate "+props.articletitle)
        }
        canvas.setAttribute("fx-visible", false)
      }
    }

    startAnimation()

    window.addEventListener('scroll', startAnimation)
  }

  render() {

    return (
        <canvas width="1650" height="765"
          className="canvas-three"
          banner={this.props.banner && this.props.banner}
          articletitle={this.props.articletitle && this.props.articletitle}
          ref={this.canvasContainer && this.canvasContainer}
        >
        </canvas>
    )
  }
}

export default Marquee
