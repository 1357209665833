import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Scene from "../components/scene"
//import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Articles = () => (
    <StaticQuery
      query={graphql`
        query {
          allStrapiArticle(sort: {fields: order}) {
            edges {
              node {
                title
                body
                legend
                strapiId
                thumb_left {
                  childImageSharp {
                    fixed(width: 1000, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                thumb_right {
                  childImageSharp {
                    fixed(width: 1000, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      `}

      render={data =>
        data.allStrapiArticle.edges.map(
          (item) =>
          <AniLink paintDrip to={"article/"+item.node.strapiId} color="white">
            <section id={item.node.strapiId} key={item.node.strapiId} className="section section-article fx-item">
              <div className="canvas">
                <Scene thumbleft={item.node.thumb_left.childImageSharp.fixed} thumbright={item.node.thumb_right.childImageSharp.fixed} articletitle={item.node.title} />
              </div>
              <div className="section-inner row">
                <div className="col s10 m6 l6">
                  {item.node.title && item.node.title}
                </div>
                <div className="col s10 m4 l4">
                  {item.node.legend && item.node.legend}
                </div>
                <div className="col s2 m2 l2">
                  Plus
                </div>
              </div>
            </section>
          </AniLink>
        )
      }
    />
)

export default Articles
