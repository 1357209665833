import React, { useEffect } from 'react'
import PropTypes from "prop-types"
//import { Link } from "gatsby"
//import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Headroom from "headroom.js";
//import Navigation from "./nav"

const Header = ({ siteTitle }) => {

  useEffect(() => {
    let myElement = document.querySelector("header")
    let headroom  = new Headroom(myElement)
    headroom.init()
  })

  return (
    <header id="header" className="headroom">
      <div className="header-inner">
        <div className="header-menu row">
          <div className="col s4 m3 l3">
            <ul className="menu">
              <li className="menu-item">
                <AniLink paintDrip to="/about" color="white">à propos</AniLink>
              </li>
            </ul>
          </div>
          <div className="col s4 l6 m6">
            <h4><AniLink paintDrip to="/" color="white">{siteTitle}</AniLink></h4>
          </div>
          <div className="col s4 m3 l3">
            <ul className="menu">
              <li className="menu-item">
                <a href="#footer">contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
