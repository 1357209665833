import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Marquee from '../components/marquee'
import { Helmet } from "react-helmet"
import favicon from '../images/favicon.png'

export const query = graphql`
  query ArticleTemplate($id: Int!) {
    strapiArticle(strapiId: { eq: $id }) {
      strapiId
      title
      body
      legend
      banner {
        childImageSharp {
          fixed(height: 765, quality: 100) {
            ...GatsbyImageSharpFixed
            src
          }
        }
      }
      gallery {
        id
        legend
        image {
          childImageSharp {
            fixed(width: 1650, quality: 100) {
              ...GatsbyImageSharpFixed
              src
            }
          }
        }
      }
    }
  }
`

const ArticleTemplate = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.strapiArticle.title}, {data.strapiArticle.legend} | Atelier Harfang | renens, switzerland</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <section className="section section-banner fx-item">
        <div className="section-inner">
          {data.strapiArticle.banner.childImageSharp.fixed && <Marquee banner={data.strapiArticle.banner.childImageSharp.fixed} articletitle={data.strapiArticle.title} />}
        </div>
      </section>

      <section className="section section-content fx-item">
        <div className="section-inner">
          <h1 className="title">{data.strapiArticle.title}, {data.strapiArticle.legend}</h1>
        </div>
      </section>

      {data.strapiArticle.gallery.map(
        (item) =>
          <section key={item.id} className="section section-gallery fx-item">
            {item.image && <img src={item.image.childImageSharp.fixed.src} alt={item.legend}/>}
          </section>
      )}

      {data.strapiArticle.body && <section className="section section-about section-about-article fx-item">
        <div className="section-inner row">
          <div className="row">
            <div className="col s12 m12 l12 col-about text-format" dangerouslySetInnerHTML={{ __html: data.strapiArticle.body }} />
          </div>
        </div>
      </section>}
    </Layout>
  )
}

export default ArticleTemplate
