import React from "react"
import { StaticQuery, graphql } from "gatsby"

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiSettings {
          contact
          about
        }
      }
    `}
    render={data =>
      <section className="section section-about fx-item">
        <div className="section-inner row">
          <div className="row">
            {data.strapiSettings.about && <div className="col s12 m12 l12 col-about text-format" dangerouslySetInnerHTML={{ __html: data.strapiSettings.about }} />}
          </div>
        </div>
      </section>
    }
  />
)

export default About
