import React from "react"
import Layout from "../components/layout"
import Articles from "../components/articles"
import About from "../components/about"
import { Helmet } from "react-helmet"
import favicon from '../images/favicon.png';

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Accueil | Atelier Harfang | renens, switzerland</title>
      <link rel="icon" href={favicon} />
    </Helmet>
    <Articles />
    <About />
  </Layout>
)

export default IndexPage
