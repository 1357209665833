import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Clients = () => (
    <StaticQuery
      query={graphql`
        query {
          allStrapiClient {
            edges {
              node {
                id
                strapiId
                client
                url
              }
            }
          }
        }
      `}
      render={data =>
        data.allStrapiClient.edges.map(
          (item) =>
          <section key={item.node.strapiId} target="_blank" className="section section-client fx-item">
            <a rel="noreferrer" target="_blank" href={item.node.url} color="white">
              <h2>{item.node.client}</h2>
            </a>
          </section>
        )
      }
    />
)

export default Clients
