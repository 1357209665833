import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiSettings {
          address
          social
          contact
        }
      }
    `}
    render={data =>
      <footer id="footer">
        <div className="row">
          <div className="col s12 m2 l2">
            Atelier Harfang <sup>sàrl</sup>
          </div>
          {data.strapiSettings.address && <div className="col s12 m3 l3" dangerouslySetInnerHTML={{ __html: data.strapiSettings.address }} />}
          {data.strapiSettings.contact && <div className="col s12 m3 l3" dangerouslySetInnerHTML={{ __html: data.strapiSettings.contact }} />}
          {data.strapiSettings.social && <div className="col s12 m3 l3 push-m3 push-l3" dangerouslySetInnerHTML={{ __html: data.strapiSettings.social }} />}
        </div>
      </footer>
    }
  />
)

export default Footer
