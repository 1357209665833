import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Clients from "../components/clients"
import { Helmet } from "react-helmet"
import favicon from '../images/favicon.png'

export const query = graphql`
  query strapiAbout {
    strapiAbout {
      strapiId
      title
      description
    }
  }
`

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>À propos | Atelier Harfang | design, développement | renens, switzerland</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <section className="section section-about fx-item">
        <div className="section-inner row">
          <div className="row">
            {data.strapiAbout.description && <div className="col s12 m12 l12 col-about text-format" dangerouslySetInnerHTML={{ __html: data.strapiAbout.description }} />}
          </div>
        </div>
      </section>
      <Clients />
    </Layout>
  )
}

export default AboutPage
